<template>
  <b-card
    no-body
  >
    <b-card-body>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="customerData.image"
            :text="avatarText(customerData.name)"
            variant="light-primary"
            size="90px"
            rounded
          />
        </template>
        <h4>
          {{ customerData.name }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Update</span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            <span class="d-none d-sm-inline">Remove</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="customerData.name"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateCustomer"
      >
        Save Changes
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BMedia, BAvatar, BCard, BCardBody
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import customer from '@/store/customer'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BMedia,
    BAvatar,
    BCard,
    BCardBody,
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customer)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const customerData = ref({
      image: null,
      name: '',
      customerID: '',
      modules: [],
    })

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      customerData.value.image = base64
    })

    const updateCustomer = () => {
      console.log(customerData.value)
      store.dispatch('app-customer/updateCustomer', customerData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer Updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer Update Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    store.dispatch('app-customer/fetchCustomer', router.currentRoute.params.id)
      .then(response => {
        // eslint-disable-next-line prefer-destructuring
        customerData.value = response.data[0]
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Customer',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            message: error.message,
          },
        })
        if (error.response.status === 404) {
          customerData.value = {
            image: null,
            name: '',
            customerID: '',
            modules: [],
          }
        }
      })

    return {
      avatarText,
      customerData,
      refInputEl,
      previewEl,
      inputImageRenderer,
      updateCustomer,
    }
  },
}
</script>
